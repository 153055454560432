// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		// TODO: replace key with project one - this one is a Codename framework key
		grecaptcha.execute('6LetiJYaAAAAAKfin4mgrZaSdk2-mus3WAyutfRw', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Accept Cookies
//hide cookie policy
$(".section-cookies .sub-content .sub-btns .btn-accept").on("click", function (ev) {
	ev.preventDefault();

	console.log('clicked-accept')
	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		console.log(response)
		$('.section-cookies').addClass('mod-hide');
	});
});

$(".section-cookies .sub-content .sub-btns .btn-decline").on("click", function (ev) {
	ev.preventDefault();

	console.log('clicked-decline')

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide');
	});
});

// Mobile hamburger menu
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('#nav').toggleClass('mod-active');
});

$('#nav ul.list-nav > li a[href^="#"]').on('click', function (e) {
	const $this = $(this);

	$this.unbind('mouseout');
	$this.toggleClass('mod-dropdown-active');
	$this.next('.sub-dropdown-wrap').toggleClass('mod-active');

	e.preventDefault();
});

// Prevent dropdowns from clicking on main menu
$(window).on('load resize', function () {
	// If tablet and below
	$('#nav ul.list-nav > li a[href^="#"]').on('click', function (e) {
		const $this = $(this);

		$this.unbind('mouseout');
		$this.toggleClass('mod-dropdown-active');

        $('#nav ul.list-nav > li div').toggleClass('mod-open');

		e.preventDefault();

		// if (window.innerWidth < 1273) {
		// 	$this.next('.sub-dropdown-wrap').toggleClass('mod-active');
		// }
	});
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect
if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}

// Animate on scroll
// AOS.init({
//     duration: 500,
//     startEvent: 'load',
//     disable: 'mobile',
//     once: true,
// });

// AOS.refresh();

// Google Maps
// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Respair', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e9e9e9"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f5f5f5"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 17
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 29
						},
						{
							"weight": 0.2
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 18
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f5f5f5"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#dedede"
						},
						{
							"lightness": 21
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#ffffff"
						},
						{
							"lightness": 16
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"saturation": 36
						},
						{
							"color": "#333333"
						},
						{
							"lightness": 40
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f2f2f2"
						},
						{
							"lightness": 19
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 20
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#fefefe"
						},
						{
							"lightness": 17
						},
						{
							"weight": 1.2
						}
					]
				}
			],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.827143587519835, -1.3021698018200336, 9, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

$(document).ready(function () {
    if ((window.document['URL'].includes('/project/') === false || !window.document['URL'].includes('/projects/') === false)) {
        localStorage.clear()
    }

	// HOME PAGE project slider OVERLAY
	$('li.item-project').mouseover(function (e) {
		var box_index = $(this).attr('id');
		$('#overlay-' + box_index).addClass('mod-show')

		$(this).mouseleave(function (e) {
			$('#overlay-' + box_index).removeClass('mod-show')
		})
	});

	// Initialize HOME page HERO IMAGES slider
	$('.list-home-hero-images').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        dots: false,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: false,
        infinite: true,
		initialSlide: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 1500,
        variableWidth: false,
		useTransform: false
	});

	// Initialize HOME page PROJECTS slider
	$('.list-home-projects').slick({
		arrows: true,
		centerMode: false,
		dots: false,
		draggable: false,
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		speed: 800,
		variableWidth: true,
		width: 395,
		prevArrow: "<img class='slick-prev' src='./assets/svg/arrow-left.svg' />",
		nextArrow: "<img class='slick-next' src='./assets/svg/arrow-right.svg' />"
	});

	// Initialize PROJECTS page PROJECTS slider
		$('.list-other-sectors').slick({
			arrows: true,
			centerMode: false,
			dots: false,
			draggable: false,
			infinite: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			speed: 800,
			variableWidth: true,
			width: 395,
			prevArrow: "<img class='slick-prev' src='./assets/svg/arrow-left.svg' />",
			nextArrow: "<img class='slick-next' src='./assets/svg/arrow-right.svg' />",
		});


	// Initialize HOME page TESTIMONIALS slider
	$('.list-testimonials').slick({
		arrows: false,
		autoplay: true,
        autoplaySpeed: 12000,
		dots: true,
		infinite: true,
        pauseOnHover: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 1500,
		variableWidth: false,
	});


	// Initialize HOME page TESTIMONIALS Images slider
	$('.list-testimonial-images').slick({
		arrows: false,
		autoplay: true,
        autoplaySpeed: 12000,
		dots: false,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 1500,
		variableWidth: false,
	});

	// Initialize PROJECT page HERO IMAGES slider
	$('.list-project-hero-images').slick({
		autoplay: true,
		arrows: false,
		centerMode: true,
		dots: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1300,
		variableWidth: true,
	});

	// Initialize JOINERY page GALLERY slider
	if ($(window).width() >= 960) {
		$('.list-joinery-images').slick({
			autoplay: true,
			arrows: false,
			centerMode: true,
			dots: true,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 1300,
			variableWidth: true,
		});
	}

	// Initialize KINGERLEE HOMES page GALLERY slider
	if ($(window).width() >= 960) {
		$('.list-kingerlee-homes-images').slick({
			autoplay: true,
			arrows: false,
			centerMode: true,
			dots: true,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 1300,
			variableWidth: true,
		});
	}

	// Initialize PROJECT page MORE PROJECTS slider
		$('.list-more-projects').slick({
            arrows: true,
            centerMode: false,
            dots: false,
            draggable: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 800,
            variableWidth: true,
            width: 395,
            prevArrow: "<img class='slick-prev' src='./assets/svg/arrow-left.svg' />",
            nextArrow: "<img class='slick-next' src='./assets/svg/arrow-right.svg' />",
		});

	// Initialize PROJECT page CONTENT slider
	$('.list-contents').slick({
		autoplay: false,
		arrows: false,
		dots: true,
		draggable: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	});
});